import { axiosOnce as axios } from '@/axios_once'

export default {
  getLocales(keys) {
    return axios.post('/api/i18n', { keys: keys })
  },
  getVisitUpdates(reservationId) {
    return axios.get(`/api/reservations/${reservationId}/updates`)
  },
  approveChangeRequest(eventId) {
    return axios.put(`/api/change_requests/${eventId}/approve`)
  },
  rejectChangeRequest(eventId) {
    return axios.delete(`/api/change_requests/${eventId}`)
  },
  cancelVisit(visitId, params) {
    return axios.delete(`/api/cancellations/${visitId}`, { params: { do: true, ...params } })
  },
  requestVisitCancel(visitId, userType, trigger) {
    return axios.delete(`/api/cancellations/${visitId}`, { params: { for: userType, trigger: trigger } })
  },
  searchCleaners(query, clientId) {
    return axios.post(`/api/cleaners/search`, { query: query, client_id: clientId })
  },
  respondToDispute(disputeId, params) {
    return axios.patch(`/api/disputes/${disputeId}`, params)
  },
}
